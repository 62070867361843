<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span> 单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <div class="search_center">
              <span>发生日期：</span>
              <el-date-picker
                class="date_width"
                v-model="occurCreatedDate"
                :pickerOptions="occurPickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="发生日期（始）"
                end-placeholder="发生日期（末）"
              >
              </el-date-picker>
            </div>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">登记流程</el-button>
          <el-button type="warning" icon="el-icon-plus" @click="add">新增</el-button>
          <el-button type="success" style="padding: 0"
            ><a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/支出登记管理操作手册.pdf"
              >操作文档</a
            ></el-button
          >
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.applyType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED">
              <span slot="label">未提交 {{ `(${this.statistics.undetermind})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL">
              <span slot="label">审批中 {{ `(${this.statistics.approval})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT">
              <span slot="label">不通过 {{ `(${this.statistics.approvalReject})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS">
              <span slot="label">已审核 {{ `(${this.statistics.approvalPass})` }}</span>
            </el-tab-pane>
          </el-tabs>
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :key="Math.random()"
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" width="50" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="expenseNumber"
              width="160"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              align="center"
              width="100"
              prop="createName"
              sortable
              label="填报人"
            ></el-table-column>
            <el-table-column
              align="center"
              width="100"
              prop="applyName"
              sortable
              label="申请人"
            ></el-table-column>
            <template v-if="parameter.applyType != 'UNDETERMINED'">
              <el-table-column
                align="center"
                sortable
                width="110"
                prop="expenseType"
                label="费用类型"
              >
                <template slot-scope="scope">
                  {{ scope.row.expenseType | dict(dictData.expenseType) }}
                </template></el-table-column
              >
              <el-table-column
                align="center"
                sortable
                min-width="320"
                :show-overflow-tooltip="false"
                prop="expenseBelongName"
                label="费用所属"
              >
              </el-table-column>
              <el-table-column
                align="center"
                :show-overflow-tooltip="false"
                sortable
                width="110"
                prop="companyType"
                label="申请单位"
              >
                <template slot-scope="scope">
                  {{ scope.row.companyType | dict(dictData.companyType) }}
                </template></el-table-column
              >
            </template>
            <el-table-column align="center" sortable width="110" prop="sumCost" label="申请金额">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template></el-table-column
            >

            <el-table-column
              align="center"
              min-width="110"
              sortable
              prop="taskDate"
              label="申请时间"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="110"
              sortable
              prop="occurDate"
              label="发生日期"
            >
              <template slot-scope="scope">
                {{ scope.row.occurDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" sortable width="150" prop="statusName" label="流程进度">
            </el-table-column>

            <el-table-column prop="title" align="center" fixed="right" width="120" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  v-if="scope.row.status != 0"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  @click.stop="edit(scope.row)"
                  v-if="scope.row.status == 0 || scope.row.status == 5"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Remove_Bgc"
                  @click.stop="del(scope.row)"
                  v-if="scope.row.status == 0"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog width="75%" title="库管登记流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/库管支出登记流程图.png" alt="库管登记流程图" width="100%" />
      </div>
    </el-dialog>

    <Edit
      :isShow.sync="showEditDialog"
      :getTitle="getTitle"
      v-if="showEditDialog"
      :options="form"
    ></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      parameter: {
        expenseNumber: null,
        pageNow: 1,
        pageSize: 50,
        total: 0,
        applyType: 'UNDETERMINED', //1未审核，2审核中，3已审批
        registrationType: 'INVENTORY_KEEPER', //库管
      },
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      drawer: false,
      createdDate: [],
      occurCreatedDate: [],
      statistics: {
        undetermind: 0,
        approval: 0,
        approvalReject: 0,
        approvalPass: 0,
      },
      getTitle: '',
      dictData: {
        expenseType: [],
        companyType: [],
      },
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      occurPickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.occurCreatedDate, 0, v.minDate.getTime())
            this.$set(this.occurCreatedDate, 1, '')
          }
        },
      },
    }
  },
  watch: {
    'parameter.applyType': {
      immediate: true, //初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
    occurCreatedDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.occurCreatedDate = []
        }
      },
    },
  },

  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog

    this.$api.dict
      .listSysDictData('EXPENSE_TYPE', true)
      .then(res => {
        this.dictData.expenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    getData() {
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }
      if (this.occurCreatedDate && this.occurCreatedDate != []) {
        this.parameter.occurStartDate = new Date(this.occurCreatedDate[0]).getTime()
        this.parameter.occurEndDate = new Date(this.occurCreatedDate[1]).getTime()
      } else if (this.occurCreatedDate == null) {
        this.parameter.occurStartDate = null
        this.parameter.occurEndDate = null
      }

      this.loading = true
      // let obj = { ...this.parameter, initiatorId: this.userInfo.staffId }

      this.$api.register
        .getApplyForList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
            this.tableData.forEach(item => {
              if (item.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT') {
                item.expenseBelongName = item.businessContent
              }
            })
          }

          this.getApplyStatistics()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    add() {
      this.getTitle = '新增登记单'
      this.form = {}
      this.form.list_s = 0
      this.showEditDialog = true
    },
    detail(row) {
      this.form = row.deepClone()
      if (row.status == 100) {
        // 点击已完成审核的详情
        this.form.list_s = 3
      } else {
        this.form.list_s = 2
      }

      this.getTitle = '查看登记单详情'
      this.showEditDialog = true
    },
    edit(row) {
      this.getTitle = '编辑登记单'
      this.form = row.deepClone()
      // this.form.list_s = 1
      this.form.list_s = 0
      this.showEditDialog = true
    },
    re() {
      this.form = {}
      this.getData()
      this.showEditDialog = false
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.occurStartDate = null
      this.parameter.occurEndDate = null
      this.parameter.expenseNumber = null
      this.createdDate = []
      this.occurCreatedDate = []
      this.parameter.pageNow = 1
      this.getData() /** 加载  数据 **/
    },
    /** 删除 **/
    del(row) {
      this.$confirm('确认删除该条登记单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.register
            .deleteRegister({ id: row.id })
            .then(res => {
              this.$message({
                type: 'success',
                message: '删除成功',
              })
              this.getData()
            })
            .catch(err => {
              console.log('错误：', err)
            })
        })
        .catch(() => {
          //取消
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    getApplyStatistics() {
      this.$api.register
        .getApplyStatistics({
          registrationType: 'INVENTORY_KEEPER',
        })
        .then(res => {
          if (res.data) {
            res.data.forEach(v => {
              if (v.applyType == 'UNDETERMINED') {
                this.statistics.undetermind = v.sumQuantity
              } else if (v.applyType == 'APPROVAL') {
                this.statistics.approval = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_REJECT') {
                this.statistics.approvalReject = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_PASS') {
                this.statistics.approvalPass = v.sumQuantity
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate() {
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
